<template>
  <div>
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-card v-else>
      <b-form>
        <b-row>
          <b-col
            cols="12"
            lg="3"
          >
            <b-card
              v-if="item"
              class="w-30 p-0 card-info border mt-2"
            >
              <b-card-title class="my-2 mx-1">
                Details
              </b-card-title>
              <div style="pointer-events: none">
                <b-list-group class="d-flex flex-column">
                  <b-list-group-item class="d-flex align-items-center">
                    <feather-icon
                      icon="AlertCircleIcon"
                      size="15"
                      class="align-middle mr-1"
                    />
                    <span><b>Status: </b>{{ item.status }}</span>
                  </b-list-group-item>
                  <b-list-group-item class="d-flex align-items-center">
                    <feather-icon
                      icon="LogInIcon"
                      size="15"
                      class="align-middle mr-1"
                    />
                    <span><b>Login: </b>{{ item.username }}</span>
                  </b-list-group-item>
                  <b-list-group-item class="d-flex align-items-center">
                    <feather-icon
                      icon="BarChart2Icon"
                      size="15"
                      class="align-middle mr-1"
                    />
                    <span><b>Tariff: </b>{{ item.tariff.name }}</span>
                  </b-list-group-item>
                  <b-list-group-item class="d-flex align-items-center">
                    <feather-icon
                      icon="HomeIcon"
                      size="15"
                      class="align-middle mr-1"
                    />
                    <span><b>IP Address: </b>{{ item.ip_address }}</span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="9"
          >
            <b-card> <SessionsTable /></b-card>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BCard, BSpinner, BCardTitle, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState, mapActions } from 'vuex'
import SessionsTable from './SessionsTable.vue'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BSpinner,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    SessionsTable,
  },
  data() {
    return {
      loading: false,
      form: {
        name: null,
        username: null,
        password: null,
        client: null,
        partner: null,
        tariff_id: null,
        subnet_id: null,
        ip_address: null,
      },
    }
  },
  computed: {
    ...mapState('vpnDialups', {
      tariffList: 'tariffList',
      subnetList: 'subnetList',
      assignedIp: 'assignedIp',
      item: 'item',
    }),
  },
  async mounted() {
    const { id } = this.$route.params
    this.loading = true
    if (id) {
      await Promise.all([this.getTariffs(), this.getSubnets()])
      await this.fetchModel(id)
    } else {
      this.$router.push({ name: 'error-404' })
    }
    this.loading = false
  },
  methods: {
    ...mapActions('vpnDialups', [
      'getTariffs',
      'getSubnets',
      'getItem',
    ]),
    async fetchModel(id) {
      try {
        await this.getItem(id)
        this.form.name = this.item.name
        this.form.id = this.item.id
        this.form.username = this.item.username
        this.form.password = this.item.password
        this.form.client = this.item.client
        this.form.partner = this.item.partner
        this.form.ip_address = this.item.ip_address
        if (this.item.tariff) {
          this.form.tariff_id = this.item.tariff.id
        }
        if (this.item.subnet) {
          this.form.subnet_id = this.item.subnet.id
        }
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
