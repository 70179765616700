var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.items.length <= 0 && _vm.isLoading)?_c('b-spinner',{staticClass:"position-absolute",staticStyle:{"top":"50%","left":"48%"},attrs:{"variant":"primary","label":"Spinning"}}):_c('b-card',{staticClass:"w-30 p-0 card-info border"},[_c('b-card-title',{staticClass:"m-2"},[_vm._v(" Sessions ")]),(!_vm.isError)?_c('b-row',{staticClass:"p-2"},[_c('b-col',{attrs:{"md":"6","order-md":"1","order":"2"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchRows()}}},[_c('b-form-row',{staticClass:"align-items-end"},[_c('b-col',[((_vm.searchFields && _vm.searchFields.length > 0) || (_vm.orderBy && _vm.orderBy.length > 0) || (_vm.sortedBy && _vm.sortedBy.length > 0))?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-body align-middle mr-25",attrs:{"icon":"XIcon","title":"Reset filters","size":"24"},on:{"click":_vm.resetFilter}}):_vm._e()],1)],1)],1)],1)],1):_vm._e(),(!_vm.isError)?_c('div',{staticClass:"p-2"},[_c('vue-good-table',{ref:"VueGoodTable",attrs:{"is-loading":_vm.isLoading,"columns":_vm.fields,"rows":_vm.items,"compact-mode":"","total-rows":_vm.pagination.total,"mode":"remote","sort-options":{
          enabled: true,
          multipleColumns: true,
          initialSortBy: {field: _vm.orderBy, type: _vm.sortedBy}
        },"pagination-options":{
          enabled: true,
          perPage: _vm.perPage,
          setCurrentPage: _vm.page
        }},on:{"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.columnFilterFn}},[_c('template',{slot:"loadingContent"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Spinning"}})],1),_c('template',{slot:"pagination-bottom"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":_vm.pageSizeOptions},on:{"change":_vm.perPageChanged},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(_vm.pagination.total)+" entries ")])],1),_c('div',[(_vm.pagination.last_page !== 1)?_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":_vm.page,"total-rows":_vm.pagination.total,"per-page":_vm.perPage,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.changePage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388)}):_vm._e()],1)])])],2)],1):_c('div',[_c('p',{staticClass:"m-2",staticStyle:{"color":"red"}},[_vm._v(" Oops! 😖 You have not access to this table. ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }