<template>
  <div>
    <b-spinner
      v-if="items.length <= 0 && isLoading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-card
      v-else
      class="w-30 p-0 card-info border"
    >
      <b-card-title class="m-2">
        Sessions
      </b-card-title>
      <b-row
        v-if="!isError"
        class="p-2"
      >
        <b-col
          md="6"
          order-md="1"
          order="2"
        >
          <b-form @submit.prevent="searchRows()">

            <b-form-row class="align-items-end">
              <b-col>
                <feather-icon
                  v-if="(searchFields && searchFields.length > 0) || (orderBy && orderBy.length > 0) || (sortedBy && sortedBy.length > 0)"
                  v-b-tooltip.hover
                  icon="XIcon"
                  title="Reset filters"
                  size="24"
                  class="text-body align-middle mr-25"
                  @click="resetFilter"
                />
              </b-col>
            </b-form-row>
          </b-form>
        </b-col>
      </b-row>
      <div
        v-if="!isError"
        class="p-2"
      >
        <vue-good-table
          ref="VueGoodTable"
          :is-loading="isLoading"
          :columns="fields"
          :rows="items"
          compact-mode
          :total-rows="pagination.total"
          mode="remote"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
            initialSortBy: {field: orderBy, type: sortedBy}
          }"
          :pagination-options="{
            enabled: true,
            perPage: perPage,
            setCurrentPage: page
          }"
          @on-sort-change="onSortChange"
          @on-column-filter="columnFilterFn"
        >
          <template slot="loadingContent">
            <b-spinner
              variant="primary"
              label="Spinning"
            />
          </template>
          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="perPage"
                  :options="pageSizeOptions"
                  class="mx-1"
                  @change="perPageChanged"
                />
                <span class="text-nowrap"> of {{ pagination.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  v-if="pagination.last_page !== 1"
                  :value="page"
                  :total-rows="pagination.total"
                  :per-page="perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="changePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
      <div v-else>
        <p
          class="m-2"
          style="color: red"
        >
          Oops! 😖 You have not access to this table.
        </p>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BPagination, BRow, BCol, BForm, BFormRow, BFormSelect, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import IndexPagesMixin from '@/mixins/IndexPages.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BCard, BCardTitle, BPagination, VueGoodTable, BRow, BCol, BForm, BFormRow, BFormSelect, BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    IndexPagesMixin,
  ],
  data() {
    return {
      fields: [
        {
          field: 'acctstarttime',
          label: 'Start Time',
          filterOptions: {
            enabled: true,
            placeholder: 'Start Time',
            filterValue: '',
          },
        },
        {
          field: 'acctstoptime',
          label: 'Stop Time',
          filterOptions: {
            enabled: true,
            placeholder: 'Stop Time',
            filterValue: '',
          },
        },
        {
          field: 'acctinputoctets',
          label: 'Amount Uploaded',
          filterOptions: {
            enabled: true,
            placeholder: 'Amount Uploaded',
            filterValue: '',
          },
        },
        {
          field: 'acctoutputoctets',
          label: 'Amount Downloaded',
          filterOptions: {
            enabled: true,
            placeholder: 'Amount Downloaded',
            filterValue: '',
          },
        },
        {
          field: 'acctsessiontime',
          label: 'Total online time',
          filterOptions: {
            enabled: true,
            placeholder: 'Total online time',
            filterValue: '',
          },
        },
        {
          field: 'framedipaddress',
          label: 'Assigned IP',
          filterOptions: {
            enabled: true,
            placeholder: 'Assigned IP',
            filterValue: '',
          },
        },
      ],
      isLoading: true,
    }
  },
  computed: {
    ...mapState('vpnDialupsSessions', {
      items: 'items',
      pagination: 'pagination',
      searchFields: 'searchFields',
      orderBy: 'orderBy',
      sortedBy: 'sortedBy',
    }),
  },
  methods: {
    ...mapActions('vpnDialupsSessions', {
      fetchItems: 'getItems',
      setSearchFields: 'setSearchFields',
      setOrderBy: 'setOrderBy',
      setSortedBy: 'setSortedBy',
    }),
  },
}
</script>

<style lang="scss">
</style>
